@import "./variables.scss";

.login {
  &__wrapper {
    width: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 3em 10em 2em;
    background: #fff;
    text-align: center;
    box-shadow: $shadow-primary;
    border-radius: 0.5em;

    h2 {
      margin: 0.5em 0 1em;
      color: $deep-blue;
      font-family: bold 2em $font-family-primary;
    }
    
    input {
      margin: 0 0 1.2em;
      border-color: $light-blue;

      &::placeholder {
        color: $light-gray;
      }
    }

    .anticon svg {
      fill: $light-blue;
    }

    .logo {
      min-width: 50px;
      box-shadow: $shadow-primary;
      border-radius: 50%;
      padding: 7.5px;
      display: inline-block;

      img {
        width: 50px;
        height: 50px;
      }
    }

    .error {
      font-weight: bold;
      color: $red;
      padding: 2em;
    }

    .slack {
      background: $off-white;
      color: $deep-blue;
      padding: 1em;
      margin-bottom: 2em;
      border-radius: 8px;
      border: none;
      
      .ant-card-body {
        padding: 0;
      }

      .icon {
        margin-right: 0.5em;
      }
    }

    .ant-input-affix-wrapper {
      margin: 0.5em 0;

      .ant-input-prefix {
        top: 16px;
      }

      input {
        margin: 0;
      }
    }

    .btn {
      width: 100%;
      font-size: initial;
      margin: 2em 0;
      transition: all .3s;

      &:hover, &:focus {
        transform: scale(1.05)
      }
    }

    .login-footer {
      margin: 1em 0;
    }

    .signup-link { 
      margin: 0 0.5em;
      font-weight: bold;
    }
  }

  &-form__wrapper {
    text-align: center;
    display: grid;
    width: 400px;
    height: 380px;
    grid-area: 2 / 2;
    border: 1px solid rgba(86, 127, 160, 0.2);
    border-radius: 3px;
    transition: box-shadow .3s;
    padding: 40px 40px;
    align-items: center;
    align-self: center;
  }
}

.signup {
  position: relative;
  width: 100%;
  min-height: 100%;

  &-details {

    &__inputs {
      display: grid;
      gap: 30px;
      padding-top: 1em;
      text-align: center;

      dl {
        dt, dd { 
          display: inline-block; 
          width: 50%;
          padding: 0 1.1em;
        }

        dt {
          text-align: right;
        }

        dd {
          text-align: left;
        }
      }
    }
      
    &__buttons {
      width: 100%;
      max-width: 400px;
      margin: 2em auto 2em;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 50px;
      justify-items: center;

      &.success {
        display: none;
      }

      &.single {
        grid-template-columns: 1fr;
      }

      &.loading .btn-finish {
        cursor: not-allowed;
      }
      
      .btn {
        width: 160px;
        height: 3em;

        &-hollow {
          background: #fff;
          color: $deep-blue;
          border: none;
        }
      }
    }
  }

  &__wrapper {
    padding: 3em;
    max-width: 800px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    background: #fff;
    box-shadow: $shadow-primary;

    h2 {
      text-align: center;
      color: $deep-blue;
      margin: 1em 0 2em;
    }

    .logo {
      width: 65px;
      margin: 0 auto;
      box-shadow: $shadow-primary;
      border-radius: 50%;
      padding: 7.5px;
      display: block;

      img {
        width: 50px;
        height: 50px;
      }
    }
    
    .signup-form {
      max-width: 500px;
      margin: 0 auto;
      padding: 3em 2em 0;

      .summary {
        font-size: 1.3em;
        text-align: center;
      }
    }

    .error {
      font-weight: bold;
      color: $red;
      padding: 2em;
    }
  }
}

.integration-sync {
  position: relative;
  width: 100%;
  min-height: 100%;

  &__wrapper {
    padding: 3em;
    max-width: 800px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    background: #fff;
    box-shadow: $shadow-primary;

    h2 {
      text-align: center;
      color: $deep-blue;
      margin: 1em 0 2em;
    }

    .logo {
      width: 50px;
      margin: 0 auto;
      padding: 7.5px;
      display: block;

      img {
        width: 50px;
        height: 50px;
      }
    }
    
    .spin {
      margin: 0 auto;
      display: block;
    }

    .error {
      color: $red;
      text-align: center;
      font-weight: bold;
      margin: 1em; 
    }
  }
}
