$font-family-primary: Karla, sans-serif;
$font-family-secondary: Roboto, sans-serif;

$off-white: #fafcfe;
$green: #58c7b8;
$yellow-green: #c9dcac;
$yellow: #ffd25f;
$orange: #fcac71;
$red: #fa6f7c;
$pink: #facdd1;

$pale-blue: #f1f6ff;
$very-light-blue: #ecf2fc;
$light-blue: #d0dff8;
$sky-blue: #A7C3F8;
$slate-blue: #7288b2;
$deep-blue: #145fd5;
$dark-blue: #113882;

$ash-purple: #4D4DAC;

$gray: #647594;
$subtle-gray: #e0eafa;
$very-subtle-gray: #f0f0f0;
$light-gray: #637caa;
$body-gray: #464f5f;
$dark-gray: #252C38;

$btn-shadow-color: #bcceed;

$shadow-primary: 0 2px 7px rgba(66, 66, 66, 0.1);
$shadow-bold: 0 0 15px rgba(55, 55, 55,.2);